// import * as React from 'react';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
// import ImageListItemBar from '@mui/material/ImageListItemBar';
// import Grid from '@mui/material/Grid';
// import Delete from '@material-ui/icons/Delete';
// import IconButton from '@mui/material/IconButton';

// import { serverURL } from '../../helpers';

// export default function StandardImageList({ itemData, edit, setGallery }) {
//   const action = (item) => {
//     setGallery({ gallery: itemData.filter(e => e._id !== item._id) });
//   };

//   return (
//     <ImageList sx={{ width: '100%', height: 'auto' }} cols={4} gap={8}>
//       {itemData?.map((item) => (
//         <ImageListItem key={item._id}>
//           <img
//             srcSet={`${serverURL}/uploaded-files/${item.src}`}
//             src={`${serverURL}/uploaded-files/${item.src}`}
//             alt={item.title}
//             loading="lazy"
//             style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//           />
//           {edit && (
//             <ImageListItemBar
//               position="top"
//               actionIcon={
//                 <IconButton
//                   aria-label={`delete ${item.title}`}
//                   onClick={() => action(item)}
//                   sx={{ color: 'white' }}
//                 >
//                   <Delete />
//                 </IconButton>
//               }
//               sx={{
//                 background: 'rgba(0, 0, 0, 0.5)',
//               }}
//             />
//           )}
//         </ImageListItem>
//       ))}
//     </ImageList>
//   );
// }


import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Grid from '@mui/material/Grid';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { serverURL } from '../../helpers';

export default function StandardImageList({ itemData, edit, setGallery }) {
  // Stav pre modálne zobrazenie
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  // Funkcia pre otvorenie modálneho okna s obrázkom
  const handleClickOpen = (item) => {
    setSelectedImage(item);
    setOpen(true);
  };

  // Funkcia pre zatvorenie modálneho okna
  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const action = (item) => {
    setGallery({ gallery: itemData.filter((e) => e._id !== item._id) });
  };

  return (
    <>
      <ImageList sx={{ width: '100%', height: 'auto' }} cols={4} gap={8}>
        {itemData?.map((item) => (
          <ImageListItem key={item._id} onClick={() => handleClickOpen(item)} style={{ cursor: 'pointer' }}>
            <img
              srcSet={`${serverURL}/uploaded-files/${item.src}`}
              src={`${serverURL}/uploaded-files/${item.src}`}
              alt={item.title}
              loading="lazy"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            {edit && (
              <ImageListItemBar
                position="top"
                actionIcon={
                  <IconButton
                    aria-label={`delete ${item.title}`}
                    onClick={(e) => {
                      e.stopPropagation(); // Zabránime spusteniu modálneho okna pri mazaní
                      action(item);
                    }}
                    sx={{ color: 'white' }}
                  >
                    <Delete />
                  </IconButton>
                }
                sx={{
                  background: 'rgba(0, 0, 0, 0.5)',
                }}
              />
            )}
          </ImageListItem>
        ))}
      </ImageList>

      {/* Modálne okno pre zobrazenie obrázka */}
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent>
          {selectedImage && (
            <img
              src={`${serverURL}/uploaded-files/${selectedImage.src}`}
              alt={selectedImage.title}
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
